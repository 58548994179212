/* Full-screen background setup */
.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../background-image.jpg');/* Replace with your background image */
    background-size: cover;
    background-position: center;
    text-align: center;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  /* Logo styling */
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo {
    width: 800px;
    height: 100px; /* Adjust the logo size as needed */
    border-radius: 50%; /* Apply rounded effect if needed */
  }
  
  /* Welcome and title text styling */
  .welcome-text {
    font-size: 24px;
    margin: 0;
  }
  
  .lottery-title {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0 30px;
  }
  
  /* Buttons container */
  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;
    max-width: 300px;
  }
  
  /* Main button styling */
  .main-button {
    background-color: #A7E0F2;
    color: black;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .main-button:hover {
    background-color: #89C3DB;
  }
  