/* Center the main participation container */
.participation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../background-image.jpg'); /* Replace with your actual background image */
    background-size: cover;
    background-position: center;
    color: white;
    font-family: Arial, sans-serif;
    text-align: center;
}

/* Logo styling */
.logo-container {
    margin-bottom: 20px;
}

.logo {
    width: 100px; /* Adjust logo size as needed */
    border-radius: 50%;
}

/* Title and participation messages */
.lottery-title {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
}

.participated-message {
    font-size: 24px;
    color: white; /* Red color for emphasis */
    margin: 5px 0;
}

.digit-info {
    font-size: 32px;
    font-weight: bold;
    color: white; /* Red color for emphasis */
    margin-bottom: 20px;
}

/* Opening time box styling */
.opening-time {
    background-color: white;
    color: black;
    width: 80%;
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
}

.opening-time p {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
}

.opening-time h1 {
    font-size: 32px;
    color: red;
    margin: 10px 0;
}

/* Refresh link styling */
.refresh-link {
    font-size: 16px;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
    display: inline-block;
}
