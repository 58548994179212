/* Center the voucher page container */
.voucher-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../background-image.jpg'); /* Replace with your actual background image */
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 0 20px; /* Add padding for small screens */
}

/* Logo styling */
.logo-container {
    margin-bottom: 20px;
}

.logo {
    width: 120px; /* Adjusted logo size for visibility */
    border-radius: 50%;
}

/* Title and subtitle styling */
.lottery-title {
    font-size: 36px;
    font-weight: bold;
    margin: 10px 0;
    color: white; /* Ensure title contrasts against background */
}

.subtitle {
    font-size: 20px;
    color: white;
    margin: 10px 0 30px;
}

/* Input field and button container */
.input-container {
    width: 80%;
    max-width: 400px; /* Consistent width for alignment */
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-field {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border: 2px solid #ccc;
    border-radius: 8px;
    text-align: center;
    outline: none;
    background-color: white;
    color: black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    margin-bottom: 15px; /* Space between input and button */
}

.input-field:focus {
    border-color: #A7E0F2;
}

/* Redeem button styling */
.redeem-button {
    background-color: #A7E0F2;
    color: black;
    font-size: 18px;
    font-weight: bold;
    padding: 15px 0;
    width: 30%; /* Full width of container */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.redeem-button:hover {
    background-color: #89C3DB;
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.redeem-button:active {
    transform: translateY(0); /* Reset button position on click */
}
