/* Center the content box in the viewport with a background image */
.result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../background-image.jpg'); /* Replace with your background image path */
    background-size: cover;
    background-position: center;
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* Semi-transparent overlay to darken the background if needed */
.result-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    z-index: 1;
}

/* Main content box on top of the overlay */
.content-box {
    position: relative;
    background-color: white;
    color: black;
    width: 90%;
    max-width: 300px;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 2;
}

/* Header with logo and title */
.header {
    margin-bottom: 15px;
}

.logo {
    width: 50px;
    margin-bottom: 10px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

/* Participation message and chosen digit styling */
.participated-message {
    font-size: 16px;
    color: red;
    font-weight: bold;
    margin: 5px 0;
}

.digit-info {
    font-size: 24px;
    font-weight: bold;
    color: red;
    margin-bottom: 20px;
}

/* Centered video box with iframe styling */
.video-box {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

iframe {
    width: 100%;
    height: 180px;
    border-radius: 8px;
    border: 1px solid #ddd;
}

/* Help Center button styling */
.main-button {
    background-color: #A7E0F2;
    color: black;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 15px;
}

.main-button:hover {
    background-color: #89C3DB;
}
