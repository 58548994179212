/* Container for the input data section */
.input-data-container {
    width: 90%;
    max-width: 400px;
    margin: 5% auto;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: Arial, sans-serif;
}

/* Individual input box wrapper for spacing */
.input-box {
    margin-bottom: 20px;
}

/* Input field styling */
.input-field {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s;
    text-align: center; /* Center text in input field */
}

/* Border color change on focus for better UX */
.input-field:focus {
    border-color: #007bff;
}

/* Styling for the available link */
.available-link {
    display: block;
    margin-top: 8px;
    font-size: 14px;
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.2s;
}

/* Link color change on hover for interactivity */
.available-link:hover {
    color: #0056b3;
}
